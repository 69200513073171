import React from "react";
import { Linkedin } from "./svg/Svg";

const year = new Date().getFullYear();

export default function Footer() {

    return <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
        <div className="d-flex align-items-center">
            <img className="footer-img" alt="Logo"/>
            <span alt='Desarrollado por Kirb Díaz Alfaro' tabIndex={0}>Desarrollado por Kirb Díaz Alfaro.</span>
            <span alt='Todos los derechos reservados' tabIndex={0}>©{`${year}`} Todos los derechos reservados.</span>
        </div>

        <ul className="nav justify-content-end list-unstyled d-flex">
            <li><a  aria-label="Linkedin" target="_blank" rel="noreferrer"
                href="https://www.linkedin.com/in/kirb-diaz-alfaro" alt='Linkedin Kirb Diaz '><Linkedin tabIndex={0}/></a></li>
        </ul>
    </footer>;
}