import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, Navbar, Container } from 'react-bootstrap'
import { useBuildingModal, BuildingModal } from './Modals'

export default function NavigationBar() {

  const { show, toggle } = useBuildingModal();

  // const [key, setKeyValue] = useState(() => '');

  // const handleKey = (eventKey) => {
  //   setKeyValue(eventKey);
  // }

  // useEffect(() => {
  //   handleKey((window.location.pathname).replace('/', ''))
  // }, [])


  return (
    <>
      <Navbar className='navbar' variant="light" expand="lg">
        <Container>
          <NavLink className={() => "navbar-brand"} type="button" to="/">
            <img className="brandLogo" src="/images/mainLogo.gif" alt="Kirb Diaz" />
          </NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavLink
                to="about"
                className={
                  ({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                }>
                Sobre mí
              </NavLink>
              <NavLink
                to="projects"
                className={
                  ({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                }>
                Proyectos
              </NavLink>
              <NavLink
                to="contactMe"
                className={
                  ({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                }>
                Contacto
              </NavLink>
              <NavLink
                to="sequence_aligner"
                className={
                  ({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                }>
                Sequence Aligner
              </NavLink>
              <Nav.Link onClick={toggle}>Descargar CV</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <BuildingModal
        show={show}
        hide={toggle}
      />
    </>
  )
}
