import React, { useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Container, Card, Form, FloatingLabel, Button } from 'react-bootstrap';
import { DNA } from '../../components/svg/Svg';


const SequenceForm = () => {

  /*Sweet Alert*/
  const MySwal = withReactContent(Swal)

  /* Form Validation */
  const [validated, setValidated] = useState(false);

  /*Form data*/
  const [seqA, setSeqA] = useState("");
  const [seqB, setSeqB] = useState("");

  const resetForm = () => {
    setSeqA("")
    setSeqB("")
  }

  const selectFileA = () => {
    document.getElementById("fileInputA").click()
  }

  const processFileA = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      setSeqA(e.target.result);
    }
  }

  const selectFileB = () => {
    document.getElementById("fileInputB").click()
  }

  const processFileB = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      setSeqB(e.target.result);
    }
  }

  const sendData = async () => {
    let data_formated = {
      'seqA': seqA,
      'seqB': seqB
    }

    await fetch("https://backend.kirbdiaz.com/sequenceAligner", {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: data_formated
      })
    }).then(response => {
      if (response.status === 200) {
        return response.blob()
      }
      else {
        return Promise.reject(response.text())
      }
    })
      .then(blob => blob.text())
      .then(text => {
        const blob = new Blob([text], { type: 'plain/text' })
        const file = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = 'aligment.txt'
        a.href = file
        a.click()
      })
      .catch(message => message.then(resolve => sweetAlert(resolve)))
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      event.preventDefault();
      sendData();
      resetForm();
      setValidated(false);
    }
  }

  const sweetAlert = (message) => {
    MySwal.fire({
      title: <p>{message}</p>,
      icon: 'error'
      /*didOpen: () => {
        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
        MySwal.showLoading()
      },
    }).then(() => {
      return MySwal.fire(<p>Shorthand works too</p>)
    })*/
    })
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <FloatingLabel controlId="sequenceALabel" label="First Sequence">
          <Form.Control type="text" value={seqA} placeholder="Write or upload a sequence" pattern="\b(?:(?![bjoxzBJOXZ])[a-zA-Z])+\b" onChange={(e) => setSeqA(e.target.value)} required />
          <Form.Control.Feedback type="invalid">
            Please write or upload a valid sequence
          </Form.Control.Feedback>
        </FloatingLabel>
        <Form.Label controlId="labelFileInputA" className='download-label'> Choose file:
          <Form.Control className="d-none" id="fileInputA" type="file" onChange={(e) => processFileA(e)} />
          <Button id='uploadFileA_btn' onClick={selectFileA}>Upload</Button>
        </Form.Label>
        <FloatingLabel controlId="sequenceBLabel" label="Second Sequence">
          <Form.Control type="text" value={seqB} placeholder="Write or upload a sequence" pattern="\b(?:(?![bjoxzBJOXZ])[a-zA-Z])+\b" onChange={(e) => setSeqB(e.target.value)} required />
          <Form.Control.Feedback type="invalid">
            Please write or upload a valid sequence
          </Form.Control.Feedback>
        </FloatingLabel>
        <Form.Label controlId="labelFileInputB" className='download-label'> Choose file:
          <Form.Control className="d-none" id="fileInputB" type="file" onChange={(e) => processFileB(e)} />
          <Button id='uploadFileB_btn' onClick={selectFileB}>Upload</Button>
        </Form.Label>
      </Form.Group>
      <Button type="submit" id="sendData_btn"><DNA size={24} /> Align Sequences</Button>
    </Form>
  )
}

export default function SequenceAligner() {
  return (
    <>
      <Container>
        <Card className="text-center" id="alignerCard">
          <Card.Header>
            <DNA size={56} />
          </Card.Header>
          <Card.Title className="card-title">
            Sequence Aligner
          </Card.Title>
          <Card.Body>
            <SequenceForm />
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}