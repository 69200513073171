import './/Styles'
import { Routes, Route } from 'react-router-dom'
import Home from './views/Home';
import ContactMe from './views/Navbar/ContactMe';
import About from './views/Navbar/About';
import Projects from './views/Navbar/Projects';
import NavigationBar from './components/NavigationBar';
import SequenceAligner from './views/Navbar/SequenceAligner';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <NavigationBar />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/projects' element={<Projects />}></Route>
        <Route path='/contactMe' element={<ContactMe />}></Route>
        <Route path='/sequence_aligner' element={<SequenceAligner />}></Route>
      </Routes>
      <Footer />
    </>

  );
}

export default App;
