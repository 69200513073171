import React, { useState } from 'react'
import { Container, Card, Form, FloatingLabel, Button } from 'react-bootstrap';
import { Telegram } from '../../components/svg/Svg';


const ContactForm = () => {
  const [validated, setValidated] = useState(false);

  /*Form data*/
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const resetForm = () => {
    setEmail("")
    setPhone("")
    setMessage("")
  }

  const sendMessage = async () => {
      let message_formated = message + '\n\n\n'
      + "Medios de contacto \n\n"
      + "Correo electronico: " + email + '\n'
      + "Telefono: " + phone
      
      await fetch("https://backend.kirbdiaz.com/contactMe", {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          message: message_formated
        })
      })
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
    else {
      event.preventDefault();
      sendMessage();
      resetForm();
    }

  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <FloatingLabel controlId="emailLabel" label="Correo">
          <Form.Control type="email" value={email} placeholder="Ingrese un correo" onChange={(e) => setEmail(e.target.value)} required />
          <Form.Control.Feedback type="invalid">
            Por favor ingrese un correo electrónico
          </Form.Control.Feedback>
        </FloatingLabel>
        <FloatingLabel controlId="phoneLabel" label="Teléfono">
          <Form.Control type="phone" value={phone} placeholder="Ingrese un número de teléfono" onChange={(e) => setPhone(e.target.value)} />
        </FloatingLabel>
        <FloatingLabel controlId="messageLabel" label="Mensaje">
          <Form.Control as="textarea" value={message} placeholder="¡Trabajemos juntos!" onChange={(e) => setMessage(e.target.value)} required />
          <Form.Control.Feedback type="invalid">
            Debe escribir un mensaje
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
      <Button type="submit" id="sendMessage_btn"><Telegram size={24} /> Enviar mensaje</Button>
    </Form>
  )
}

export default function ContactMe() {
  return (
    <>
      <Container>
        <Card className="text-center" id="contactCard">
          <Card.Header>
            <Telegram size={48} />
          </Card.Header>
          <Card.Body>
            <ContactForm />
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}