import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

function useBuildingModal(){
    const [show, setShow] = useState(false)

    function toggle(){
        setShow(!show)
    }

    return {
        show,
        toggle
    }
}

function BuildingModal({show, hide}) {

    return (
        <Modal
            show={show}
            onHide={hide}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Atención</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Este sitio alojará mi portafolio como ingeniero en sistemas y desarrollador, actualmente se encuentra en desarrollo, disculpe las molestias
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export {
    useBuildingModal,
    BuildingModal
}