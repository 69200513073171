import React from 'react'
import { Container, Card } from 'react-bootstrap';

export default function Project() {
  return (
    <>
      <Container>
        <Card className="text-center">
          <Card.Body>
              <div className="loading-animation">
                <div className="preloader"></div>
                <h4>Proyectos en desarrollo...</h4>
              </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}